import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { useAuth } from "../contexts/AuthContext"

import { 
    Alert,
    Box,
    Button,
    Container,
    Grid,
    Typography,
    useMediaQuery
} from '@mui/material'

import { useTranslation, Trans } from 'react-i18next';

import BackgroundImage from '../assets/landing_background.png'

import LogoIcon from '../assets/logo_icon.png'

const styles = {
    background: {
        margin: 0,
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        overflow: 'visible',
        backgroundRepeat: 'repeat',
        height: '100vh'
    }
};

function Home() {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const { t, i18n } = useTranslation()

    const history = useHistory()

    const { signinAsGuest } = useAuth()

    const handleLogin = () => {
        history.push('/login')
    };

    const handleSignup = () => {
        history.push('/signup')
    };

    const handleTryAsGuest = async () => {
        try {
            setError('')
            setLoading(true)
            await signinAsGuest()
            history.push("/")
        } catch (error) {
            console.log(error)
            setError(t("login.failed_to_login_as_guest", { error: error.code }))
        }
        setLoading(false)
    };

    const mediaSizeSmallUp = useMediaQuery('(min-width:600px)');

    useEffect(() => {
        
        if (mediaSizeSmallUp) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
        return () => {
            document.body.style.overflow = 'visible'
        }
    }, [i18n, mediaSizeSmallUp])

    return (
        <>

            {error &&
                <Alert severity="error" onClose={() => { setError('') }}>
                    {error}
                </Alert>
            }

            <div style={styles.background}>
                <Container maxWidth="sm" align="right">
                    <Button 
                        disabled={loading}
                        onClick={handleLogin}>
                       <Trans i18nKey="home.login">
                        login
                        </Trans>
                    </Button>
                </Container>
                <Box sx={{ mt: 8, flexGrow: 1 }}>
                    <Container maxWidth="sm">
                        <Grid
                            container
                            direction="row"
                            justifyItems="center"
                            alignItems="flex-start"
                            spacing={1}
                            columns={12}
                        >
                            <Grid container item xs={12} alignItems="flex-end">
                                <Grid item xs={12} sm={2} marginRight={"5px"}>
                                    {
                                        mediaSizeSmallUp ?
                                        <img src={LogoIcon} style={ {width:'84px'}} alt="icon" />
                                        :
                                        <img src={LogoIcon} style={ {width:'64px'}} alt="icon" />
                                    }
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography color="#403D39" variant="h4">
                                    <Trans i18nKey="home.title">
                                        Welcome to
                                        </Trans>
                                    </Typography>
                                    <Typography color="#403D39" variant={"h3"}>
                                        Automattesera
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} mt={6}>
                                    <Typography color="#403D39" variant="h5">
                                        <Trans i18nKey="home.practice1">
                                        Practice1
                                        </Trans>
                                    </Typography>
                                </Grid>

                                <Grid item mt={2}>
                                    <Typography color="#403D39" variant="h5">
                                        <Trans i18nKey="home.practice2">
                                        Practice2
                                        </Trans>
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item mt={4} xs={12} textAlign="center">
                                <Button
                                    disabled={loading}
                                    onClick={handleSignup}
                                    size="small"
                                    sx={{paddingBottom:0, verticalAlign:"bottom"}}>
                                    <Trans i18nKey="home.sign_up">
                                    Sign up
                                    </Trans>
                                </Button> 

                                <Typography color="#403D39" variant="b1">
                                    <Trans i18nKey="home.or">
                                        or
                                    </Trans>
                                </Typography>

                                <Button
                                    disabled={loading}
                                    onClick={handleTryAsGuest}
                                    size="small"
                                    sx={{paddingBottom:0, verticalAlign:"bottom"}}>
                                <Trans i18nKey="home.try_as_guest">
                                    Try as guest
                                    </Trans>
                                </Button>
                            </Grid>

                            <Grid item xs={12} sx={{mt:4, mb:6}}>
                                <Grid item xs={12} sx={{p:2, backgroundColor: "#97C149" }} >
                                <Typography color="white" variant="b2">
                                        <Trans i18nKey="home.description1">
                                            Description
                                        </Trans>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{p:2, backgroundColor: "#97C149" }} >
                                    <Typography color="white" variant="b2">
                                        <Trans i18nKey="home.description2">
                                            Description
                                        </Trans>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>

                </Box>

            </div>
        </>
    )
}

export default Home