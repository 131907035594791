import React, { useEffect, useState } from 'react'

import { Link } from "react-router-dom"
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useTranslation, Trans } from 'react-i18next';

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'

import { useAuth } from "../contexts/AuthContext"

import BackgroundImage from '../assets/landing_background.png'

const styles = {
    background: {
        margin: 0,
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
    }
};

function ForgotPassword() {
    const { t } = useTranslation();

    const { resetPassword } = useAuth()
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const validationSchema = yup.object({
        email: yup
            .string(t("enter_your_email"))
            .email(t("enter_a_valid_email"))
            .required(t("email_is_required")),
    })

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                setError('')
                setMessage('')
                setLoading(true)
                await resetPassword(values.email)
                setMessage(t('resetpassword.check_email'))
            } catch (error) {
                console.log(error)
                setError(t("resetpassword.error", { error: error.code }))
            }
            setLoading(false)
        }
    })

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'visible'
        }
    }, [])

    return (
        <>
            <div style={styles.background}>

                <Container sx={{ mt: 4 }} maxWidth="xs">
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent spacing="1">

                            {error &&
                                <Alert severity="error" onClose={() => { setError('') }}>
                                    {error}
                                </Alert>
                            }
                            {message &&
                                <Alert onClose={() => { setMessage('') }}>
                                    {message}
                                </Alert>
                            }

                            <Typography variant="h2" sx={{ mt:2, mb: 1.5, textAlign: "center" }}>
                                <Trans i18nKey="resetpassword.title">
                                    Reset Password
                                </Trans>
                            </Typography>

                            <form onSubmit={formik.handleSubmit}>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="email"
                                    name="email"
                                    label={t("Email")}
                                    required
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                                <Button disabled={loading} color="primary" variant="contained" fullWidth sx={{ mt: 3, mb: 2 }} type="submit">
                                    <Trans i18nKey="resetpassword.submit">
                                        Reset Password
                                    </Trans>
                                </Button>
                            </form>

                            <div align="center">
                                <Link to="/login">
                                    <Trans i18nKey="resetpassword.login">
                                        Log In
                                    </Trans>
                                </Link>
                            </div>

                        </CardContent>
                    </Card>

                </Container>

            </div>
        </>

    )
}

export default ForgotPassword