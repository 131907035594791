import React, { useContext, useState, useEffect } from 'react'

import { auth } from "../firebase"

import {
    createUserWithEmailAndPassword,
    deleteUser,
    linkWithCredential,
    EmailAuthProvider,
    reauthenticateWithCredential,
    signInWithEmailAndPassword,
    signInAnonymously,
    signOut,
    sendPasswordResetEmail,
    updateEmail,
    updatePassword
} from "firebase/auth"

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password)
    }

    function linkAnonymousAccount(email, password) {
        try {
            const credential = EmailAuthProvider.credential(email, password)
            return linkWithCredential(auth.currentUser, credential)
        } catch (error) {
            console.log(error)
        }
    }

    function signin(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
    }

    function signinAsGuest() {
        return signInAnonymously(auth)
    }

    function signout() {
        return signOut(auth)
    }

    function resetPassword(email) {
        return sendPasswordResetEmail(auth, email)
    }

    function changeEmail(email) {
        return updateEmail(currentUser, email)
    }

    function changePassword(password) {
        return updatePassword(currentUser, password)
    }

    function reauthenticate(password) {
        var credential = EmailAuthProvider.credential(
            currentUser.email,
            password
        )

        return reauthenticateWithCredential(currentUser, credential)
    }    

    function deleteAccount() {
        return deleteUser(currentUser)
    }

    useEffect(() => {
        try {
            const unsubscribe = auth.onAuthStateChanged(user => {
                setCurrentUser(user)
                setLoading(false)
            })

            return unsubscribe
        } catch (error) {
            console.log(error)
        }
    }, []
    )

    const value = {
        currentUser,
        signup,
        linkAnonymousAccount,
        signin,
        signinAsGuest,
        signout,
        resetPassword,
        changeEmail,
        changePassword,
        reauthenticate,
        deleteAccount,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
