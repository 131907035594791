import { collection, addDoc, getDocs, onSnapshot, orderBy, limit, query, serverTimestamp, Timestamp, where } from "firebase/firestore"
import { db } from "../../firebase"

// Add game results to provided user
export const addGame = async (uid, playerid, game, results) => {

    const gameData = {
        created: serverTimestamp(),
        type: game.type,
        level: game.level,
        table: game.table,
        retries: results.retries,
        numQuestions: results.numQuestions,
        timeToComplete: results.time
    }

    const docRef = await addDoc(collection(db,
        `users/${uid}/players/${playerid}/games`),
        gameData)

    console.log(`Added game to db, ID: ${docRef.id}`)
}

// Add a new player to the provided user
export const addPlayer = async (uid, name) => {
    const docRef = await addDoc(collection(db, `users/${uid}/players`), {
        name: name
    })
    console.log(`Added player to db, ID: ${docRef.id}`)
}

// Fetch players and listen to updates 
export const fetchPlayers = async (uid, onPlayersUpdated) => {
    const q = query(collection(db, `users/${uid}/players`));
    return onSnapshot(q, (querySnapshot) => {
        const fetchedPlayers = []
        querySnapshot.forEach((doc) => {
            fetchedPlayers.push({ id: doc.id, ...doc.data() })
        })
        onPlayersUpdated(fetchedPlayers)
    })
}

// Fetch best rounds given a second category
export const fetchTopList = async (uid, playerId, type, level, table, number, onRecordAvailable) => {
    console.log("fetchTopList  " + uid + ", " + playerId)
    const gamesRef = collection(db, `users/${uid}/players/${playerId}/games`)
    let q = null;

    if (type === "multiplication_table") {
        q = query(gamesRef, where("type", "==", type), where("level", "==", level), where("table", "==", table), orderBy("timeToComplete"), limit(number));
    } else {
        q = query(gamesRef, where("type", "==", type), where("level", "==", level), orderBy("timeToComplete"), limit(number));
    }

    const querySnapshot = await getDocs(q);
    console.log("Got TopList docs: " + querySnapshot.size)
    let records = []
    querySnapshot.forEach((doc) => {
        records.push(doc);
    });
    onRecordAvailable(records)
}

// Fetch number of games player last week 
export const fetchNumberOfGamesPlayedLastSevenDays = async (uid, playerId, onNumberOfGamesPlayerLastWeek) => {
    console.log("fetchNumberOfGamesPlayedLastSevenDays  " + uid + ", " + playerId)

    const midnightSevenDaysAgo = Timestamp.fromMillis(Timestamp.now().toDate().setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000)

    const gamesRef = collection(db, `users/${uid}/players/${playerId}/games`)
    const q = query(gamesRef, where("created", ">", midnightSevenDaysAgo));

    const querySnapshot = await getDocs(q);
    console.log("Got Games Played Last Seven Days: " + querySnapshot.size)
    onNumberOfGamesPlayerLastWeek(querySnapshot.size)
}
