import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import { Alert, AppBar,
  Box, Container, Divider,
  IconButton, ListItemIcon,
  Menu, MenuItem, Popover,
  Toolbar, Typography } from '@mui/material'

import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import LanguageIcon from '@mui/icons-material/Language'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import LogoutIcon from '@mui/icons-material/Logout'

import LogoOnWhite from '../assets/logo_on_white.svg'

import { useTranslation, Trans } from 'react-i18next'

import { useAuth } from "../contexts/AuthContext"
import { addGame } from "../services/firebase"

import Game from "./Game"
import PlayerSelect from "./PlayerSelect"
import GameSelect from "./GameSelect"
import Results from "./Results"

function Main() {

  const { i18n } = useTranslation();

  const { currentUser, signout } = useAuth()
  const history = useHistory()

  const [error, setError] = useState("")
  const [player, setPlayer] = useState("")
  const [game, setGame] = useState("")
  const [prevGame, setPrevGame] = useState("")
  const [results, setResults] = useState("")
  const [anchorEl, setAnchorEl] = useState(null)
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null)

  const lngs = {
    en: { nativeName: 'English' },
    sv: { nativeName: 'Svenska' }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const handleChangePlayer = () => {
    setGame("")
    setPlayer("")
    setResults("")
    handleClose()
  }

  const handleAccount = () => {
    history.push("/account")
  };

  const handleSelectPlayer = (player) => {
    console.log("handle select player: " + player.name + " , " + player.id)
    setResults("")
    setPlayer(player)
  }

  const handleGameEnd = (results) => {
    console.log("handle game end : " + game + "\n" + results)

    addGame(currentUser.uid, player.id, game, results)

    setResults(results)
  }

  const handleSelectGame = (game) => {
    console.log("handle select game : " + game.type + " level: " + game.level + " questions: " + game.questions)
    setResults("")
    setGame(game)
    setPrevGame(game)
  }

  const handleTryAgain = () => {
    console.log("handle try again : ")
    setGame("")
    setResults("")
  }

  async function handleLogout() {
    setError('')
    try {
      await signout()
      history.push('/home')
    }
    catch (e) {
      console.log("Error signing out user \n" + e)
      setError('Failed to log out')
    }
  }

  function handleLinkAccount() {
    setError('')
    history.push('/signup')
  }

  const handleSelectLanguage = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setLanguageAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>

            { /* Logo */}
            <img src={LogoOnWhite} style={{ width: '32px' }} alt="icon" />

            { /* App name */}
            <Typography
              variant="h6"
              noWrap
              component="div"
              color="white"
              sx={{ mx: '10px', display: { xs: 'none', sm: 'block' } }}
            >
              Automattesera
            </Typography>

            <Box sx={{ flexGrow: 2 }} />

            {player &&
              <Typography
                variant="h6"
                color="white"
                noWrap
                component="div"
                children={player.name}
              >
              </Typography>
            }

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <SupervisedUserCircleIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                disabled={!player}
                onClick={handleChangePlayer}>
                <ListItemIcon>
                  <PeopleAltIcon color="#403D39" />
                </ListItemIcon>
                <Typography variant="inherit" color="#403D39">
                  <Trans i18nKey="appbar.menu.changeplayer">
                    Change player
                  </Trans>
                </Typography>
              </MenuItem>
              {!currentUser.isAnonymous &&
                <MenuItem
                  disabled={currentUser.isAnonymous}
                  onClick={handleAccount}>
                  <ListItemIcon>
                    <AccountBoxIcon color="#403D39" />
                  </ListItemIcon>
                  <Typography variant="inherit" color="#403D39">
                    <Trans i18nKey="appbar.menu.account">
                      Account
                    </Trans>
                  </Typography>
                </MenuItem>
              }
              {
                currentUser.isAnonymous &&
                <MenuItem onClick={handleLinkAccount}>
                  <ListItemIcon>
                    <PersonAddIcon color="#403D39" />
                  </ListItemIcon>
                  <Typography variant="inherit" color="#403D39">
                    <Trans i18nKey="appbar.menu.sign_up">
                      Create account
                    </Trans>
                  </Typography>
                </MenuItem>
              }
              {
                <MenuItem aria-describedby={'language-popover'} onClick={handleSelectLanguage}>
                  <ListItemIcon>
                    <LanguageIcon color="#403D39" />
                  </ListItemIcon>
                  <Typography variant="inherit" color="#403D39">
                    <Trans i18nKey="appbar.menu.language">
                      Language
                    </Trans>
                  </Typography>
                </MenuItem>
              }
              {
                !currentUser.isAnonymous &&
                <Divider />
              }
              {
                !currentUser.isAnonymous &&
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon color="#403D39" />
                  </ListItemIcon>
                  <Typography variant="inherit" color="#403D39">
                    <Trans i18nKey="appbar.menu.logout">
                      Log out
                    </Trans>
                  </Typography>
                </MenuItem>
              }
            </Menu>
            <Popover
              id='language-popover'
              open={Boolean(languageAnchorEl)}
              anchorEl={languageAnchorEl}
              onClose={handleCloseLanguage}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {Object.keys(lngs).map((lng) => (
                <MenuItem
                  key={lng}
                  style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }}
                  type="submit"
                  onClick={() => {
                    i18n.changeLanguage(lng)
                    handleCloseLanguage()
                  }}>
                  {lngs[lng].nativeName}
                </MenuItem>
              ))}
            </Popover>
          </Toolbar>
        </AppBar>
      </Box>

      {
        error &&
        <Alert>error</Alert>
      }

      <Container maxWidth="sm">

        {
          !player &&
          <PlayerSelect onSelectPlayer={handleSelectPlayer} />
        }

        {
          player && !game && !results &&
          <GameSelect playerName={player.name} prevGame={prevGame} onSelectGame={handleSelectGame} />
        }

        {game && !results &&
          <Game game={game} onGameEnd={handleGameEnd} />
        }

        {results &&
          <Results player={player} game={game} results={results} onTryAgain={handleTryAgain} />
        }

      </Container>

    </>
  )
}

export default Main