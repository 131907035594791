import * as React from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { useTranslation, Trans } from 'react-i18next';

import ReactGA from 'react-ga4'

import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles'

import "@fontsource/mochiy-pop-p-one"
import "@fontsource/open-sans/400.css"

import Home from "./Home"
import Signup from "./Signup"
import Login from "./Login"
import Main from "./Main"
import ForgotPassword from "./ForgotPassword"
import Account from './Account'
import PrivateRoute from './PrivateRoute'

import { AuthProvider } from '../contexts/AuthContext'

let theme = createTheme({

  palette: {
    background: {
      default: "#FCFEFC"
    },
    primary: {
      main: '#2F97C1'
    },
    secondary: {
      main: '#E33E7F'
    }, 
    error: {
      main: '#ef3054'
    }
  },

  typography: {
    fontFamily: ['Mochiy Pop P One', 'Open Sans'].join(',')
  },

  components: {

    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#403D39'
        },
        b1: {
          whiteSpace: 'pre-line'
        },
        b2: {
          fontFamily: ['Open Sans'],
          fontWeight: 'bold',
          whiteSpace: 'pre-line'
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
        },
        contained: {
          background: '#99C24D',
          '&:hover': {
            backgroundColor: '#69892F',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: '#99C24D',
            },
            '&$disabled': {
              backgroundColor: '#9F9A93',
            },
          },
          '&$disabled': {
            color: '#9F9A93',
          },
        },

      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '2.5rem',
          minHeight: 64,
          minWidth: 128,
          borderRadius: 32,
          textAlign: 'center',
          marginBottom: 20,
        },
        contained: {
          fontSize: '2.5rem',
          background: '#2F97C1',
          '&:hover': {
            backgroundColor: '#237291',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: '#2F97C1',
            },
            '&$disabled': {
              backgroundColor: '#9F9A93',
            },
          },
          '&$disabled': {
            color: '#9F9A93',
          },
        },
      },
    }
  },
})

theme = responsiveFontSizes(theme)

function App() {

  const { i18n, t } = useTranslation()

  // Handle accept cookies
  const handleAcceptCookies = () => {
    console.log('Cookies accepted')
    // Initialize Google Analytics
    if (process.env.REACT_APP_MEASUREMENT_ID != null) {
      ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID)
    }
  }

  // Handle decline cookies
  const handleDeclineCookies = () => {
    console.log("Cookies declined")
    // Remove Google Analytics cookies
    if (process.env.REACT_APP_MEASUREMENT_ID != null) {
      Cookies.remove('_ga')
      Cookies.remove('gat')
      Cookies.remove('_gid')
    }
  }

  React.useEffect(() => {
    i18n.changeLanguage()
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookies()
    }
  }, [i18n]);

  return (
    <React.Fragment>
      <Router>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <CookieConsent
              enableDeclineButton
              onAccept={handleAcceptCookies}
              onDecline={handleDeclineCookies}
              buttonText={t("i_accept")}
              declineButtonText={t("i_decline")}
              buttonClasses="outlined outlinedPrimary"
              style={{ background: '#2F97C1', color: '#fff', fontSize: '1rem' }}
              buttonStyle={{ borderRadius: '10px', borderColor: "#fff", borderWidth: '1px', borderStyle: "solid", background: '#2F97C1', color: '#fff', fontSize: '1rem' }}
              declineButtonStyle={{ borderRadius: '1px', borderColor: "#fff", borderWidth: '1px', borderStyle: "solid", background: '#2F97C1', color: '#fff', fontSize: '1rem' }}
            >
              <Trans i18nKey="app.cookie_consent">
                This website uses cookies to enhance the user experience.
              </Trans>
            </CookieConsent>
            <CssBaseline />
            <Switch>
              <PrivateRoute exact path="/" component={Main}></PrivateRoute>
              <PrivateRoute path="/account" component={Account}></PrivateRoute>
              <Route path="/home" component={Home}></Route>
              <Route path="/signup" component={Signup}></Route>
              <Route path="/login" component={Login}></Route>
              <Route path="/forgot-password" component={ForgotPassword}></Route>
            </Switch>
          </ThemeProvider>
        </AuthProvider>
      </Router>
    </React.Fragment>
  )
}

export default App;