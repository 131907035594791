
import { useRef, useEffect } from 'react';

/**
 * useEventListener is a custom React Hook that allows you to add event listeners 
 * to elements in a functional component.
 *
 * @param {string} eventName - A string representing the event to listen for, like 'click' or 'keydown'.
 * @param {function} handler - A function to be called when the event is detected.
 * @param {element} [element=window] - The DOM element to attach the event listener to, defaults to `window` if not provided.
 */
function useEventListener(eventName, handler, element = window){
    // Create a ref that stores the handler
    const savedHandler = useRef();
  
    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
      savedHandler.current = handler;
    }, [handler]);
  
    useEffect(() => {
        // Check if the element supports addEventListener
        if (!element.addEventListener) return;
  
        // Create event listener that calls handler function stored in ref
        const eventListener = event => savedHandler.current(event);
  
        // Add event listener
        element.addEventListener(eventName, eventListener);
  
        // Remove event listener on cleanup
        return () => {
          element.removeEventListener(eventName, eventListener);
        };
      },
      [eventName, element] // Re-run if eventName or element changes
    );
  };

  export default useEventListener