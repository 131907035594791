import React from 'react'

import { Container, Button, Grid } from '@mui/material'

function NumericInput({ handleClick }) {

    return (
        <Container maxWidth="xs">
            <Grid
                container
                sx={{ mt: "48px" }}
                justifyContent={{ xs: "space-around", md: "center" }}
                spacing={0}
                columns={{ xs: 12 }}>
                {Array.from(Array(10)).map((_, index) => (
                    <Grid item xs={4} sx={{ my: 1 }} textAlign={'center'} key={index} >
                        <Button
                            onClick={() => handleClick((index + 1) % 10)}
                            variant="contained"
                            sx={{ minWidth: 80, minHeight: 55, borderRadius: 10, fontSize: '1.5rem' }}
                        >
                            {(index + 1) % 10}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}

export default NumericInput
