import React, { useEffect, useState } from 'react'

import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useTranslation, Trans } from 'react-i18next';

import { 
    Alert, 
    Box, 
    Button, 
    Card, 
    CardContent, 
    CardActions, 
    Container, 
    TextField, 
    Typography } from '@mui/material'

import { useAuth } from '../contexts/AuthContext'

import BackgroundImage from '../assets/landing_background.png'

const styles = {
    background: {
        margin: 0,
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
    }
};

function Signup() {
    const { t } = useTranslation();

    const { currentUser, signup, linkAnonymousAccount } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const handleBack = () => {
        history.goBack()
    }

    const validationSchema = yup.object({
        email: yup
            .string(t('enter_your_email'))
            .email(t('enter_a_valid_email'))
            .required(t('email_is_required')),
        password: yup
            .string(t('enter_your_password'))
            .min(8, t('password_length_min_8_characters'))
            .required(t('password_is_required')),
        confirmPassword: yup
            .string(t('enter_your_password'))
            .min(8, t('password_length_min_8_characters'))
            .oneOf([yup.ref('password'), null], t('passwords_dont_match'))
            .required(t('password_is_required')),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {

            if (values.password !== values.confirmPassword) {
                return setError(t('passwords_dont_match'))
            }

            setError('')
            setLoading(true)

            try {
                setError('')
                setLoading(true)
                if(currentUser != null){
                    await linkAnonymousAccount(values.email, values.password)
                } else {
                    await signup(values.email, values.password)
                }
                history.push("/")
            } catch (error) {
                if (error.code === "auth/email-already-in-use") {
                    setError(t('user_already_exists'))
                } else {
                    console.log(error)
                    setError(t('failed_to_create_account', { error: error.code }))
                }
            }
            setLoading(false)
        }
    })

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'visible'
        }
    }, [])

    return (
        <>
            <div style={styles.background}>

                <Container sx={{ mt: 4 }} maxWidth="xs">
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent spacing="1">

                            {error &&
                                <Alert severity="error" onClose={() => { setError('') }}>
                                    {error}
                                </Alert>
                            }

                            <Typography variant="h2" sx={{ mt:2, mb: 1.5, textAlign: "center" }}>
                                <Trans i18nKey="signup.title">
                                    Sign Up
                                </Trans>
                            </Typography>

                            <form onSubmit={formik.handleSubmit}>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="email"
                                    name="email"
                                    label={t("Email")}
                                    required
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="password"
                                    name="password"
                                    label={t("Password")}
                                    type="password"
                                    required
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    label={t("Confirm_Password")}
                                    type="password"
                                    required
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                />
                                <Button disabled={loading} color="primary" variant="contained" fullWidth sx={{ mt: 3, mb: 2 }} type="submit">
                                    <Trans i18nKey="signup.submit">
                                        Submit
                                    </Trans>
                                </Button>
                            </form>

                        </CardContent>

                        <CardActions>
                            <Button onClick={handleBack} size="small">
                                <Trans i18nKey="Back">
                                    Back
                                </Trans>
                            </Button>
                        </CardActions>
                        
                    </Card>

                    <Box sx={{ mt: 2 }}>
                        <span>
                            <Trans i18nKey="signup.have_an_account">
                                Already have an account?
                            </Trans>
                            &nbsp;
                        </span>
                        <Link to="/login">
                            <Trans i18nKey="signup.log_in">
                                Log In
                            </Trans>
                        </Link>
                    </Box>

                </Container>
            </div>
        </>
    )
}

export default Signup