import React, { useEffect, useState } from 'react'

import { Box, Button, Grid, Paper, Typography } from '@mui/material'

import { useTranslation, Trans } from 'react-i18next';

import Carousel from 'react-material-ui-carousel'

import { useAuth } from "../contexts/AuthContext"
import {
    fetchTopList,
    fetchNumberOfGamesPlayedLastSevenDays
} from "../services/firebase"

import useEventListener from '../hooks/useEventListener'

// Rounds a number to one decimal place, returns a string
function roundToOneDecimal(value) {
    let res = String(Math.round((value + Number.EPSILON) * 10) / 10)
    if (res.split('.').length < 2) // pad with decimals if needed
    {
        return res + ".0"
    }
    return res
}

// Convert a Date to a string in the format "Month"
function dateToMonth(date) {
    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ]

    return monthNames[date.getMonth()]
}

export default function Results(props) {

    const { t } = useTranslation()
    const { currentUser } = useAuth()

    const [results] = useState(props.results)
    const [records, setRecords] = useState("");
    const [gamesPlayed, setGamesPlayed] = useState(0);
    const [activeResultView, setActiveResultView] = useState(0);
    const [resultsViewAutoPlay, setResultsViewAutoPlay] = useState(true);

    const onRecordsFetched = (records) => {
        setRecords(records)
    }

    const onGamesPlayedFetched = (gamesPlayed) => {
        setGamesPlayed(gamesPlayed)
    }

    function keyHandler({ key }) {
        if (key === "ArrowLeft") {
            // rotate carousel left
            activeResultView === 0 ? setActiveResultView(2) : setActiveResultView(activeResultView - 1)
            // disable results view autoplay
            setResultsViewAutoPlay(false)
        } else if (key === "ArrowRight") {
            // rotate carousel right
            activeResultView === 2 ? setActiveResultView(0) : setActiveResultView(activeResultView + 1)
            // disable results view autoplay
            setResultsViewAutoPlay(false)
        } else if (key === "Enter") {
            // restart game
            props.onTryAgain()
        }
    }

    // register keydown event listener
    useEventListener('keydown', keyHandler)

    const fetchData = () => {
        console.log(props.game)
        console.log(props.player)
        fetchTopList(currentUser.uid, props.player.id, props.game.type, props.game.level, props.game.table, 5, onRecordsFetched)
        fetchNumberOfGamesPlayedLastSevenDays(currentUser.uid, props.player.id, onGamesPlayedFetched)
    }

    useEffect(() => {
        fetchData()
        return () => {
        }
    }, [])

    return (
        <>
            <Box sx={{ my: 6 }} textAlign="center">

                {
                    results.retries === 0 &&
                    <Typography variant="h3" sx={{ my: 4 }}>
                        <Trans i18nKey="results.perfect">
                            Perfect round!
                        </Trans>
                    </Typography>
                }
                {
                    results.retries > 0 &&
                    <>
                        <Typography variant="h3" sx={{ my: 4 }}>
                            {t('results.retriesWithCount', { count: results.retries })}
                        </Typography>
                    </>
                }

                <Carousel
                    width="100%"
                    autoPlay={resultsViewAutoPlay}
                    navButtonsAlwaysVisible={false}
                    dynamicHeight={true}
                    showThumbs={true}
                    cycleNavigation={true}
                    interval={10000}
                    animation={'slide'}
                    index={activeResultView}
                    next={() => { }}
                    prev={() => {/* Do other stuff */ }}
                >
                    {/*  PAGE 1  */}

                    <Paper sx={{ height: "280px", py: 1, px: 1, mx: { xs: 0, sm: 5}, background: '#FFF8E7', borderRadius: '10%' }}>

                        <Grid container spacing={2} sx={{ height: 300 }} overflow="hidden">
                            <Grid item xs={12} sx={{ my: 5 }}>
                                <Typography variant="h4" sx={{ m: 1, mb: 2 }}>
                                    <Trans i18nKey="results.time_to_complete">
                                        Time
                                    </Trans>&nbsp;
                                </Typography>
                                <Typography variant="h2" sx={{ my: 4 }}>
                                    {roundToOneDecimal(results.time / 1000)}s
                                </Typography>

                            </Grid>
                        </Grid>
                    </Paper>

                    {/*  PAGE 2  */}

                    <Paper sx={{ height: "280px", py: 1, px: 1, mx: { xs: 0, sm: 5}, background: '#F2F7E8', borderRadius: '10%' }}>
                        <Grid container spacing={2} sx={{ height: 300 }} overflow="hidden">
                            <Grid item xs={12} sx={{ my: 5 }}>
                                {
                                    <>
                                        <Typography variant="h4" sx={{ m: 1, mb: 2 }}>
                                            <Trans i18nKey="results.games_played_this_week">
                                                Games played this week
                                            </Trans>
                                        </Typography>

                                        {
                                            <Typography variant="h2" sx={{ my: 4 }}>
                                                {gamesPlayed === 0 ? "1" : gamesPlayed}
                                            </Typography>
                                        }
                                    </>
                                }

                            </Grid>
                        </Grid>
                    </Paper>

                    {/*  PAGE 3  */}

                    <Paper sx={{ height: "280px", py: 1, px: 1, mx: { xs: 0, sm: 5}, background: '#E4F3F8', borderRadius: '10%' }}>
                        <Grid container spacing={2} sx={{ height: 300 }} overflow="hidden">
                            <Grid item xs={12} >
                                {
                                    <>
                                        {
                                        props.game.type === "multiplication_table" ? (
                                        <Typography variant="h4" sx={{ m: 1, mb: 2 }}>
                                            {t('results.your_quickest_table', { table: t(props.game.table) })}
                                            { props.game.level === 10 && ( ' - ' +
                                                t('results.hard') ) }
                                        </Typography> ) :(
                                        <Typography variant="h4" sx={{ m: 1, mb: 2 }}>
                                            {t('results.your_quickest_games_of', { game: t(props.game.type) })}
                                        </Typography>
                                        )

                                        }
                                        {
                                            records &&
                                            records.map(record => {
                                                return (
                                                    <Typography variant="h6" key={record.id} sx={{ px: 12, mx: 0.5 }}>
                                                        {
                                                            dateToMonth(record.data({serverTimestamps:'estimate'}).created.toDate())
                                                        }&nbsp;
                                                        {
                                                            String(record.data({serverTimestamps:'estimate'}).created.toDate().getDate()).padStart(2, '0')
                                                        }: &nbsp;
                                                        {roundToOneDecimal(record.data().timeToComplete / 1000)}s
                                                    </Typography>
                                                )

                                            })
                                        }
                                    </>
                                }

                            </Grid>
                        </Grid>
                    </Paper>

                </Carousel>

                <Button sx={{ my: 4 }} variant="contained" size="large" color="success" onClick={props.onTryAgain}>
                    <Trans i18nKey="results.tryagain">
                        Try again!
                    </Trans>
                </Button>

            </Box>

        </>
    )
}
