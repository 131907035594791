import React, { useEffect, useState } from 'react'

import {
    Box,
    Button,
    Chip,
    Grid,
    styled,
    Switch,
    Stack,
    Tooltip,
    Typography
} from '@mui/material'

import { useTranslation, Trans } from 'react-i18next'

import useEventListener from '../hooks/useEventListener'

const StyledSwitch = styled(Switch)(({ theme }) => ({
    width: 64,
    height: 32,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 16,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(32px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 4,
        '&.Mui-checked': {
            transform: 'translateX(32px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#FFB100',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 24,
        height: 24,
        borderRadius: 12,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 32 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#2F97C1',
        boxSizing: 'border-box',
    },
}))


export default function GameSelect(props) {

    const { t } = useTranslation();

    const [game, setGame] = useState('')

    const handleGameChange = (newGame) => {
        setGame({ ...game, type: newGame })
            };

    const handleLevelChange = (event) => {
        const level = event.target.checked ? 10 : 0
        const questions = event.target.checked ? 20 : 10
        setGame({ ...game, level: level, questions: questions })
    }

    const handleSubmit = () => {
        props.onSelectGame(game)
    }

    function keyHandler({ key }) {
        if (key === "Enter") {
            // start game
            props.onSelectGame(game)
        }
    }

    // register keydown event listener
    useEventListener('keydown', keyHandler)

    useEffect(() => {
        if (props.prevGame) {
            setGame(props.prevGame)
        }
        else { 
            setGame({ type: 'plus', level: 0, questions: 10, ghost: false }) 
        }
        return () => { }
    }, [props.prevGame])

    return (
        <>
            <Typography
                align="center"
                variant="h3"
                sx={{
                    my: { xs: 2, sm: 10 }
                }}>
                    {t('Greeting', { name: t(props.playerName) })}                    
            </Typography>

            <Typography
                align="center"
                variant="h6"
                color="primary"
                sx={{
                    my: { xs: 2, sm: 4 }
                }}>
                <Trans i18nKey="gameselection.choose_game">
                    Select game
                </Trans>
            </Typography>

            <Grid
                container
                spacing={{ xs: 0, sm: 2, md: 4 }}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={6} sm={3} textAlign="center" >
                    <Tooltip title={t("gameselection.addition")} disableFocusListener placement="top" arrow>
                        <Chip
                            variant={game.type === "plus" ? "contained" : "outlined"}
                            color="primary"
                            size="medium"
                            label="+"
                            onClick={() => handleGameChange("plus")}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={6} sm={3} textAlign="center" >
                    <Tooltip title={t("gameselection.subtraction")} disableFocusListener placement="top" arrow>
                        <Chip
                            variant={game.type === "minus" ? "contained" : "outlined"}
                            color="primary"
                            size="medium"
                            label="-"
                            onClick={() => handleGameChange("minus")}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={6} sm={3} textAlign="center" >
                    <Tooltip title={t("gameselection.division")} disableFocusListener placement="top" arrow>
                        <Chip
                            variant={game.type === "division" ? "contained" : "outlined"}
                            color="primary"
                            size="medium"
                            label="/"
                            onClick={() => handleGameChange("division")}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={6} sm={3} textAlign="center" >
                    <Tooltip title={t("gameselection.multiplication")} disableFocusListener placement="top" arrow>
                        <Chip
                            variant={(game.type === "multiplication" || game.type === "multiplication_table")  ? "contained" : "outlined"}
                            color="primary"
                            size="medium"
                            label="x"
                            onClick={() => handleGameChange("multiplication")}
                        />
                    </Tooltip>
                </Grid>
            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    my: { xs: 4, sm: 6 }
                }}>
                {
                    (game.type === "multiplication" || game.type === "multiplication_table") &&
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: 1,
                            mt: { xs: -4, sm: -5 },
                            mb: { xs: 0, sm: 2 }
                        }}>

                        <Typography
                            variant="h6"
                            color="primary"
                            sx={{ mb: 1 }}>
                                <Trans i18nKey="gameselection.choose_table">
                                    Choose table
                                </Trans>
                            </Typography>

                        <Tooltip title={t("gameselection.all_tables")} disableFocusListener placement="top" arrow>
                            <Chip
                                sx={{p:0,
                                    fontSize: '1.5rem',
                                    minHeight: {xs:24, sm:48},
                                    minWidth: {xs:64, sm:90},
                                    borderRadius: {xs:12, sm:24},
                                    textAlign: 'center',
                                    }}
                                variant={(game.table === "all" || game.type === "multiplication") ? "contained" : "outlined"}
                                color="primary"
                                size="medium"
                                label={t("gameselection.all")}
                                onClick={() => setGame({ ...game, type: "multiplication", table: "all" })}
                            />
                        </Tooltip>

                    <Grid
                        container
                        spacing={{ xs: 1, sm: 1, md: 2 }}
                        sx={{ mb: 2 }}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        gap={0}
                    >
                {
                Array.from(Array(10).keys()).map(x => ++x).map(number => {
                    return (
                    <Grid 
                        key={number} item 
                        xs={2}>
                        <Tooltip title={t("gameselection.table", { number: t(number) })} disableFocusListener placement="top" arrow>
                            <Chip
                                sx={{ 
                                    p:0,
                                    fontSize: '1.5rem',
                                    minHeight: {xs:24, sm:48},
                                    minWidth: {xs:48, sm:90},
                                    borderRadius: {xs:12, sm:24},
                                    textAlign: 'center',
                                    }}
                                variant={ (game.table === number && game.type !== "multiplication") ? "contained" : "outlined"}
                                color="primary"
                                size="medium"
                                label={number}
                                onClick={() => setGame({ ...game, type:"multiplication_table", table: number })}
                                
                            />
                        </Tooltip>
                    </Grid>
)})
}

                    </Grid>
                    </Box>
                }

                <Stack direction="row" alignSelf={"center"} spacing={1} alignItems="center" >
                    <Tooltip title={t("gameselection.easy_tooltip")} disableFocusListener placement="left" arrow>
                        <Typography
                            variant="h6"
                            color={game.level === 0 ? "#403D39" : "#adadad"}
                            style={game.level === 0 ? { fontWeight: 700, fontSize: 16 } : { fontSize: 16 }  }
                        >
                            <Trans i18nKey="gameselection.easy">
                                Easy
                            </Trans>
                        </Typography>
                    </Tooltip>
                    <StyledSwitch onChange={handleLevelChange} checked={game.level === 10} />
                    <Tooltip title={t("gameselection.hard_tooltip")} disableFocusListener placement="right" arrow>
                        <Typography
                            variant="h6"
                            color={game.level === 10 ? "#403D39" : "#adadad"}
                            style={game.level === 10 ? { fontWeight: 700, fontSize: 16 } : { fontSize: 16 }}
                        >
                            <Trans i18nKey="gameselection.hard">
                                Hard
                            </Trans>
                        </Typography>
                    </Tooltip>
                </Stack>

            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1,
                    my: { xs: 4, sm: 10 }
                }}>

                <Button
                    variant="contained"
                    color="success"
                    size="large"
                    onClick={handleSubmit}
                >
                    <Trans i18nKey="gameselection.start">
                        Start!
                    </Trans>
                </Button>
            </Box>
        </>
    )
}