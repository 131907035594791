import React, { useEffect, useState } from 'react'

import { Link, useHistory } from 'react-router-dom'
import { Formik, useFormik } from 'formik'
import * as yup from 'yup'

import { useTranslation, Trans } from 'react-i18next'

import {
    Alert, Box, Button,
    Card, CardActions, CardContent,
    Container, Modal,
    TextField, Typography
} from '@mui/material'

import { useSnackbar } from 'notistack';

import { useAuth } from '../contexts/AuthContext'

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const borderStyle = {
    my: 3,
    p: 1,
    border: 1,
    borderRadius: 1,
    borderColor: '#ccc',
};

function Account() {
    const { t } = useTranslation()

    const { currentUser, changePassword, deleteAccount, reauthenticate } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [deleteAccountOpen, setDeleteAccountOpen] = React.useState(false)
    const handleDeleteAccountOpen = () => setDeleteAccountOpen(true)
    const handleDeleteAccountClose = () => setDeleteAccountOpen(false)
    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory()

    const validationSchema = yup.object({
        currentPassword: yup
            .string(t('enter_your_current_password'))
            .min(8, t('password_length_min_8_characters')),
        password: yup
            .string(t('enter_your_password'))
            .min(8, t('password_length_min_8_characters')),
        confirmPassword: yup
            .string(t('enter_your_password'))
            .min(8, t('password_length_min_8_characters'))
            .oneOf([yup.ref('password'), null], t('passwords_dont_match'))
    })

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            setLoading(true)
            setError('')

            if (values.password !== values.confirmPassword) {
                return setError("New passwords do not match")
            }

            try {
                await reauthenticate(values.currentPassword);
                await changePassword(values.password);
                enqueueSnackbar(t('account.password_updated'), { variant: 'success' })
            } catch (error) {
                setError(t('account.failed_to_update_password'))
            }
            values.currentPassword = ''
            values.password = ''
            values.confirmPassword = ''
            setLoading(false)
        }
    })

    return (

        <Container sx={{ mt: 4 }} maxWidth="xs">

            <Modal
                open={deleteAccountOpen}
                onClose={handleDeleteAccountClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <Trans i18nKey="account.delete_account_title">
                            Enter your password to confirm
                        </Trans>
                    </Typography>

                    <Formik
                        initialValues={{ password: '' }}
                        onSubmit={(values, actions) => {

                            reauthenticate(values.password).then(() => {

                                deleteAccount().then(() => {
                                    enqueueSnackbar(t('account.account_deleted'), { variant: 'success' })
                                    history.push("/")
                                }).catch((error) => {
                                    enqueueSnackbar(t('account.failed_to_delete_account'), { variant: 'error' })
                                }).finally(() => {
                                    actions.setSubmitting(false)
                                })

                            }).catch((error) => {
                                enqueueSnackbar(t('account.failed_to_authenticate_user'), { variant: 'error' })
                                actions.setSubmitting(false)
                            })
                        }}
                    >
                        {props => (
                            <form onSubmit={props.handleSubmit}>

                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="password"
                                    name="password"
                                    label={t("Password")}
                                    type="password"
                                    value={props.values.password}
                                    onChange={props.handleChange}
                                    error={props.touched.password && Boolean(props.errors.password)}
                                    helperText={props.touched.password && props.errors.password}
                                />

                                {props.errors.name && <div id="feedback">{props.errors.name}</div>}
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button disabled={loading} color="error" variant="text" sx={{ mt: 3, mb: 2 }} type="submit">
                                        <Trans i18nKey="account.delete_account">
                                            Save
                                        </Trans>
                                    </Button>
                                </Box>

                            </form>
                        )}
                    </Formik>

                    <Button sx={{ mt: 2 }} variant="text" color="primary" onClick={handleDeleteAccountClose}>
                        <Trans i18nKey="Back">
                            Back
                        </Trans>
                    </Button>

                </Box>
            </Modal>

            <Card sx={{ minWidth: 275 }}>
                <CardContent spacing="1">

                    {error &&
                        <Alert severity="error" onClose={() => { setError('') }}>
                            {error}
                        </Alert>
                    }

                    <Typography variant="h2" sx={{ mt: 2, mb: 4, textAlign: "center" }}  >
                        <Trans i18nKey="account.title">
                            Account
                        </Trans>
                    </Typography>


                    <Box sx={{ ...borderStyle }}>

                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Email
                        </Typography>

                        <Typography variant="b1" >
                            {currentUser.email}
                        </Typography>

                    </Box>


                    <Box sx={{ ...borderStyle }}>

                        <Typography variant="h6" sx={{ mb: 1 }} >
                            <Trans i18nKey="account.change_password">
                                Change Password
                            </Trans>
                        </Typography>

                        <form onSubmit={formik.handleSubmit}>
                            <TextField
                                fullWidth
                                id="currentPassword"
                                name="currentPassword"
                                label={t("Current Password")}
                                type="password"
                                value={formik.values.currentPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                                helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                margin="dense"
                                id="password"
                                name="password"
                                label={t("New Password")}
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                            <TextField
                                fullWidth
                                margin="dense"
                                id="confirmPassword"
                                name="confirmPassword"
                                label={t("Confirm_Password")}
                                type="password"
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                            />

                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button disabled={loading} color="primary" variant="contained" sx={{ mt: 3, mb: 2 }} type="submit">
                                    <Trans i18nKey="account.submit">
                                        Save
                                    </Trans>
                                </Button>
                            </Box>

                        </form>
                    </Box>

                    <Box sx={{ ...borderStyle }}>

                        <Typography variant="h6" sx={{ mb: 1 }} >
                            <Trans i18nKey="account.delete_account">
                                Delete Account
                            </Trans>
                        </Typography>

                        <Typography variant="b1" sx={{ my: 2 }}>
                            <Trans i18nKey="account.action_cannot_be_undone">
                                This action cannot be undone.
                            </Trans>
                        </Typography>


                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button disabled={loading} onClick={handleDeleteAccountOpen} color="error" variant="text" sx={{ mt: 2, mb: 2 }}>
                                <Trans i18nKey="account.delete_my_account">
                                    Delete my account
                                </Trans>
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
                <CardActions>
                    <Button size="small"><Link to="/">
                        <Trans i18nKey="Back">
                            Cancel
                        </Trans>
                    </Link></Button>
                </CardActions>
            </Card>
        </Container>

    )
}

export default Account