import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import {
    Avatar,
    Box,
    Button,
    Grid,
    IconButton,
    Modal,
    TextField,
    Typography
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'

import { useTranslation, Trans } from 'react-i18next'

import { useFormik } from 'formik'
import * as yup from 'yup'

import { useAuth } from "../contexts/AuthContext"
import { addPlayer, fetchPlayers } from "../services/firebase"

const validationSchema = yup.object({
    name: yup
        .string('Enter player name')
        .max(35)
        .required('Name is required'),
})

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
}

function stringToPastellColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    const v1 = ((hash >> (0 * 8)) & 0xff) / 255
    const v2 = (hash >> (1 * 8)) & 0xff / 255
    const v3 = (hash >> (2 * 8)) & 0xff / 255

    let hue = 360 * v1
    let saturation = 60 + 20 * v2
    let lightness = 60 + 20 * v3

    // Colors in hue span 40 - 100 are too bright background colors for white text 
    if (hue > 40 && hue < 100) {
        hue = 360 - hue
    }

    // Add a boost to blueish tints
    if (hue > 215 && hue < 265) {
        const gain = 20
        let blueness = 1 - Math.abs(hue - 240) / 25
        let change = Math.floor(gain * blueness)
        lightness += change
        saturation -= change
    }

    return "hsl(" + hue + ',' +
        (saturation) + '%,' +
        (lightness) + '%)'

    /* eslint-enable no-bitwise */
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToPastellColor(name),
            width: { xs: 90, sm: 128 },
            height: { xs: 90, sm: 128 },
        },
        //        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
        children: name,
    };
}

export default function PlayerSelect(props) {

    const { t } = useTranslation();

    const history = useHistory()

    const [players, setPlayers] = useState([])
    const { currentUser } = useAuth()

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const handleSelectPlayer = (player) => {
        props.onSelectPlayer(player)
    }

    const handleCreateAccount = () => {
        history.push('./signup')
    }

    useEffect(() => {
        const unsubscribe = fetchPlayers(currentUser.uid,
            (updatedPlayers) => { setPlayers(updatedPlayers) })
        return () => unsubscribe
    }, [currentUser.uid])

    const formik = useFormik({
        initialValues: {
            name: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                addPlayer(currentUser.uid, values.name)
                values.name = ''
            } catch (error) {
                console.log(error)
            } finally {
                handleClose()
            }
        }
    })

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <Trans i18nKey="playerselection.create.title">
                            Player name
                        </Trans>
                    </Typography>

                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            fullWidth
                            margin="normal"
                            id="name"
                            name="name"
                            label={t("Name")}
                            required
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <Button color="primary" variant="contained" fullWidth sx={{ mt: 3, mb: 2 }} type="submit">
                            <Trans i18nKey="playerselection.create.button">
                                Create
                            </Trans>
                        </Button>
                    </form>

                    <Button onClick={handleClose}>{t("Cancel")}</Button>
                </Box>
            </Modal>

            <Typography
                align="center"
                variant="h3"
                sx={{
                    my: { xs: 8, sm: 10 }
                }}>
                {
                    players.length !== 0 ?
                        <Trans i18nKey="playerselection.title">
                            Select player
                        </Trans>
                        :
                        <Trans i18nKey="playerselection.create_player">
                            Create a player
                        </Trans>
                }
            </Typography>

            <Grid
                container
                spacing={{ xs: 0, sm: 0, md: 1 }}
                rowSpacing={4}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >

                {
                    players && players.map(player => {
                        return (
                            <Grid item xs={4} sm={3} textAlign="center" key={player.id} >

                                <IconButton sx={{ p: 0 }} onClick={() => handleSelectPlayer(player)}>
                                    <Avatar {...stringAvatar(player.name)} />
                                </IconButton>
                            </Grid>
                        )
                    })}

                <Grid item xs={4} sm={3} textAlign="center" >
                    <IconButton sx={{ padding: 0 }} onClick={handleOpen}>
                        <Avatar sx={{ width: 90, height: 90 }}>
                            <AddIcon style={{ fontSize: '64px' }} />
                        </Avatar>
                    </IconButton>
                </Grid>
            </Grid>

            {
                currentUser.isAnonymous &&
                <Box mt={8} textAlign="center">
                    <Typography mb={2} color="#403D39" variant="h6">
                        <Trans i18nKey="playerselection.anonymous_details">
                            Create an account to store your players and progress.
                        </Trans>
                    </Typography>
                    <Button onClick={handleCreateAccount} size="small" >
                        <Trans i18nKey="playerselection.create_account">
                            Create Account
                        </Trans>
                    </Button>
                </Box>
            }
        </>
    )
}