import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend'

import languageEN from './locate/en/translate.json'
import languageSV from './locate/sv/translate.json'

i18n
  .use(XHR)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
//    lng: "en", // turned off for detector to work
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: ","
    },
    ns: ["translations"],
    defaultNS: "translations",
    react: {
        useSuspense: false,
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    },
    
    resources: {
        en: languageEN,
        sv: languageSV
    },
  });

export default i18n;