import React, { useEffect, useState } from 'react'

import { Link, useHistory } from "react-router-dom"
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useTranslation, Trans } from 'react-i18next';

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'

import { useAuth } from "../contexts/AuthContext"

import BackgroundImage from '../assets/landing_background.png'

const styles = {
    background: {
        margin: 0,
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
    }
};

function Login() {
    const { t } = useTranslation();

    const { signin } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const validationSchema = yup.object({
        email: yup
            .string(t("enter_your_email"))
            .email(t("enter_a_valid_email"))
            .required(t("email_is_required")),
        password: yup
            .string(t("email_is_required"))
            .min(8, t("password_length_min_8_characters"))
            .required(t("password_is_required"))
    })

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                setError('')
                setLoading(true)
                await signin(values.email, values.password)
                history.push("/")
            } catch (error) {
                if (error.code === "auth/user-not-found") {
                    setError(t("login.user_not_found"))
                } else if (error.code === "auth/wrong-password") {
                    setError(t("login.wrong_password"))
                } else {
                    console.log(error)
                    setError(t("login.failed_to_login", { error: error.code }))
                }
            }
            setLoading(false)
        }
    })

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'visible'
        }
    }, [])

    return (
        <>
            <div style={styles.background}>
                <Container sx={{ mt: 4 }} maxWidth="xs">
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent spacing="1">

                            {error &&
                                <Alert severity="error" onClose={() => { setError('') }}>
                                    {error}
                                </Alert>
                            }

                            <Typography variant="h2" sx={{ mt: 2, mb: 1.5, textAlign: "center" }}  >
                                <Trans i18nKey="login.title">
                                    Log In
                                </Trans>
                            </Typography>

                            <form onSubmit={formik.handleSubmit}>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="email"
                                    name="email"
                                    label={t("Email")}
                                    required
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="password"
                                    name="password"
                                    label={t("Password")}
                                    type="password"
                                    required
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                />
                                <Button disabled={loading} color="primary" variant="contained" fullWidth sx={{ mt: 3, mb: 2 }} type="submit">
                                    <Trans i18nKey="login.submit">
                                        Log In
                                    </Trans>
                                </Button>
                            </form>

                            <div>
                                <Link to="/forgot-password">
                                    <Trans i18nKey="login.forgot_password">
                                        Forgot Password?
                                    </Trans>
                                </Link>
                            </div>

                        </CardContent>
                    </Card>

                    <Box sx={{ mt: 2 }} >
                        <span>
                            <Trans i18nKey="login.need_account">
                                Need an account?
                            </Trans>
                            &nbsp;
                        </span>
                        <Link to="/signup">
                            <Trans i18nKey="login.sign_up">
                                Sign Up
                            </Trans>
                        </Link>
                    </Box>

                </Container>
            </div>
        </>
    )
}

export default Login