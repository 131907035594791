// https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript

// Seed generator
function xmur3(str) {
    for (var i = 0, h = 1779033703 ^ str.length; i < str.length; i++){
        h = Math.imul(h ^ str.charCodeAt(i), 3432918353)
    }
    h = (h << 13) | (h >>> 19)
    return function () {
        h = Math.imul(h ^ (h >>> 16), 2246822507)
        h = Math.imul(h ^ (h >>> 13), 3266489909)
        return (h ^= h >>> 16) >>> 0
    }
}

// Returns a function providing a random number between 0 and 1
function mulberry32(a) {
    return function () {
        var t = a += 0x6D2B79F5
        t = Math.imul(t ^ (t >>> 15), t | 1)
        t ^= t + Math.imul(t ^ (t >>> 7), t | 61)
        return ((t ^ (t >>> 14)) >>> 0) / 4294967296
    }
}

// Create a seeded random number generator
const rand = mulberry32(xmur3(Math.random())());

// Get random integer between min (inclusive) and max (inclusive
const getRandomInt = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(rand() * (max - min + 1)) + min
}

export { getRandomInt, rand }