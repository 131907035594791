import { useCallback, useEffect, useState } from 'react'
import { useSpring } from 'react-spring'

import usePrefersReducedMotion from './usePrefersReducedMotion'

function useShake({
  x = 0,
  y = 0,
  rotation = 0,
  scale = 1,
  timing = 250,
  springConfig = {
    tension: 1000,
    friction: 10,
  },
}) {
  const prefersReducedMotion = usePrefersReducedMotion()
  const [isShaked, setIsShaked] = useState(false)
  const style = useSpring({
    transform: isShaked
      ? `translate(${x}px, ${y}px)
         rotate(${rotation}deg)
         scale(${scale})`
      : `translate(0px, 0px)
         rotate(0deg)
         scale(1)`,
    config: springConfig,
  })

  useEffect(() => {

    if (!isShaked) {
      return
    }

    const timeoutId = window.setTimeout(() => {
      setIsShaked(false)
    }, timing)

    return () => {
      window.clearTimeout(timeoutId)
    }
  }, [isShaked, timing])

  const trigger = useCallback(() => {
    setIsShaked(true)
  }, [])

  let appliedStyle = prefersReducedMotion ? {} : style
  return [appliedStyle, trigger]
}

export default useShake