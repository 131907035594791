import React, { useEffect, useState } from 'react'

import { Box, LinearProgress, Typography } from '@mui/material'
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import NumericInput from './NumericInput'

import { animated } from 'react-spring'
import useShake from '../hooks/useShake'
import useEventListener from '../hooks/useEventListener'

import { getRandomInt } from '../utils/RandomNumbers'

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 1.5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : '#308fe8',
  },
}));

function Game(props) {

    const [evaluating, setEvaluating] = useState(false)
    const [previousQuestions, setPreviousQuestions] = useState([])
    const [answer, setAnswer] = useState("")
    const [correctAnswer, setCorrectAnswer] = useState("")
    const [question, setQuestion] = useState("")
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [totalRetries, setTotalRetries] = useState(0)
    const [start, setStart] = useState("")
    const [tries, setTries] = useState(0)
    const [shakeStyle, triggerShake] = useShake({ x: 12 });
    const [correctStyle, triggerCorrect] = useShake({ scale: 1.1, springConfig: { tension: 500, friction: 20 } });

    function createNewEasyMultiplicationTableQuestion() {
        const term1 = props.game.table
        const term2 = getRandomInt(0, 10)
        const question = "" + term1 + " * " + term2 + " = "
        if (previousQuestions.includes(question)) {
            return createNewEasyMultiplicationTableQuestion()
        }
        setPreviousQuestions(previousQuestions.concat(question))
        setCorrectAnswer(term1 * term2)
        setQuestion("" + term1 + " * " + term2 + " = ")
    }

    function createNewHardMultiplicationTableQuestion() {
        const reverse = getRandomInt(0, 1)
        const term1 = reverse ? props.game.table : getRandomInt(0, 10)
        const term2 = reverse ? getRandomInt(0, 10) : props.game.table
        const question = "" + term1 + " * " + term2 + " = "
        if (previousQuestions.includes(question)) {
            return createNewHardMultiplicationTableQuestion()
        }
        setPreviousQuestions(previousQuestions.concat(question))
        setCorrectAnswer(term1 * term2)
        setQuestion("" + term1 + " * " + term2 + " = ")
    }

    function createEasyQuestion() {
        switch (props.game.type) {
            case 'plus': {
                const term1 = getRandomInt(0, 10)
                const term2 = getRandomInt(0, 10)
                setCorrectAnswer(term1 + term2)
                setQuestion("" + term1 + " + " + term2 + " = ")
            }
                break;
            case 'minus': {
                const t1 = getRandomInt(0, 10)
                const t2 = getRandomInt(0, 10)
                const term1 = Math.max(t1, t2)
                const term2 = Math.min(t1, t2)
                setCorrectAnswer(term1 - term2)
                setQuestion("" + term1 + " - " + term2 + " = ")
            }
                break;
            case 'multiplication': {
                const termValues = [0, 1, 2, 5]
                const term1 = termValues[getRandomInt(0, termValues.length-1)]
                const term2 = getRandomInt(0, 10)
                setCorrectAnswer(term1 * term2)
                setQuestion("" + term1 + " * " + term2 + " = ")
            }
                break;
            case 'multiplication_table': 
                createNewEasyMultiplicationTableQuestion()
                break;
                case 'division': {
                const t1 = getRandomInt(1, 9)
                const t2 = getRandomInt(1, Math.floor(20 / t1))
                const term1 = t1 * t2
                setCorrectAnswer(term1 / t2)
                setQuestion("" + term1 + " / " + t2 + " = ")
            }
                break;
            default:
                setCorrectAnswer(0)
                setQuestion("Something is wrong, press 0 to continue")
                break
        }
    }

    function createHardQuestion() {
        switch (props.game.type) {
            case 'plus': {
                const term1 = getRandomInt(0, 100)
                const term2 = getRandomInt(0, 100 - term1)
                setCorrectAnswer(term1 + term2)
                setQuestion("" + term1 + " + " + term2 + " = ")
            }
                break;
            case 'minus': {
                const t1 = getRandomInt(0, 100)
                const t2 = getRandomInt(0, 100)
                const term1 = Math.max(t1, t2)
                const term2 = Math.min(t1, t2)
                setCorrectAnswer(term1 - term2)
                setQuestion("" + term1 + " - " + term2 + " = ")
            }
                break;
            case 'multiplication': {
                const term1 = getRandomInt(0, 10)
                const term2 = getRandomInt(0, 10)
                setCorrectAnswer(term1 * term2)
                setQuestion("" + term1 + " * " + term2 + " = ")
            }
                break;
            case 'multiplication_table':
                createNewHardMultiplicationTableQuestion()                
                break;
            case 'division': {
                const t1 = getRandomInt(1, 9)
                const t2 = getRandomInt(1, 9)
                const term1 = t1 * t2
                setCorrectAnswer(term1 / t2)
                setQuestion("" + term1 + " / " + t2 + " = ")
            }
                break;
            default:
                setCorrectAnswer(0)
                setQuestion("Something is wrong, press 0 to continue")
                break
        }
    }

    const createNewQuestion = () => {
        if (currentQuestionIndex === props.game.questions) {
            const end = Date.now()
            const time = end - start
            props.onGameEnd({ time: time, retries: totalRetries, numQuestions: currentQuestionIndex })
        } else {
            if (props.game.level === 0) {
                createEasyQuestion()
            } else {
                createHardQuestion()
            }
            setCurrentQuestionIndex(currentQuestionIndex + 1)
        }
    }

    const handleNumericInput = async (e) => {
        if (evaluating) {
            return
        }
        setEvaluating(true)
        const newAnswer = answer + e
        setAnswer(newAnswer)

        let numTries = tries
        if (correctAnswer === parseInt(newAnswer)) {
            console.log("Correct!")
            triggerCorrect()
            const timeoutId = setTimeout(function () {
                createNewQuestion()
                setAnswer("")
                setEvaluating(false)
                setTries(0)
                clearTimeout(timeoutId)
            }, 500)
            return
        } else if (correctAnswer < 10 && newAnswer.length === 1) {
            console.log("Not Correct!")
        } else if (correctAnswer < 100 && newAnswer.length === 2) {
            console.log("Not Correct!")
        } else if (newAnswer.length === 3) {
            console.log("Not Correct!")
        } else {
            console.log("Not complete!")
            setEvaluating(false)
            return
        }
        numTries++
        setTries(numTries)
        setTotalRetries(totalRetries + 1)
        if (numTries === 3) {
            console.log("No more tries")
            triggerShake()
            setTimeout(function () {
                setAnswer(correctAnswer)
                setTimeout(function () {
                    createNewQuestion()
                    setAnswer("")
                    setTries(0)
                    setEvaluating(false)
                }, 3000)
            }, 1000)
        } else {
            triggerShake()
            setTimeout(function () {
                setAnswer("")
                setEvaluating(false)
            }, 1000)
        }
    }

    function keyHandler({ key }) {
        // pass numeric keys only to the handleNumericInput function
        if (key >= 0 && key <= 9) {
            handleNumericInput(key)
        } else if (key === "Backspace") {
            setAnswer(answer.slice(0, -1))
        }
    }

    // register keydown event listener
    useEventListener('keydown', keyHandler)

    useEffect(() => {
        setStart(Date.now)
        createNewQuestion()
        return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <animated.div style={correctStyle}>
                <animated.div style={shakeStyle}>
                    <Typography align="center" variant="h3" sx={{ my: 6 }}>
                        {question} {answer}
                    </Typography>
                </animated.div>
            </animated.div>

            <Box sx={{ width: '100%', my:6, px:"5%" }}>
                <StyledLinearProgress variant="determinate" value={(currentQuestionIndex-1)*100/props.game.questions} />
            </Box>

            <NumericInput handleClick={(e) => handleNumericInput(e)} />
        </>
    )
}

export default Game
